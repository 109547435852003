import React from 'react';

import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import JoinOurTeamPage from './pages/JoinOurTeamPage';
import HRRecruiterPage from './pages/HrRecriuterPage';
import TrainerPage from './pages/TrainerPage';
import ClientsPage from './pages/ClientsPage';
import ContactPage from './pages/ContactPage';
import SharedLayout from './components/SharedLayout';
import ServicesPage from './pages/ServicesPage';
import ExecutiveRecriutmentPage from './pages/ExecuteRecruitmentPage';
import HumanResourcesPage from './pages/HumanResourcesPage';
import ManagementConsultingPage from './pages/ManagementConsultingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import TrainingPage from './pages/TrainingPage';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<SharedLayout/>}>
                    <Route index={true} element={<HomePage/>}/>
                    <Route path="/about-us" element={<AboutUsPage/>}/>
                    <Route path={'/careers/join-our-team'}>
                        <Route index={true} element={<JoinOurTeamPage/>}/>
                        <Route path={'hr-recruiter'} element={<HRRecruiterPage/>}/>
                        <Route path={'trainer'} element={<TrainerPage/>}/>
                    </Route>
                    <Route path={'/clients'} element={<ClientsPage/>}/>
                    <Route path={'/contact'} element={<ContactPage/>}/>
                    <Route path={'/services'}>
                        <Route index={true} element={<ServicesPage/>}/>
                        <Route path={'executive-recruitment'} element={<ExecutiveRecriutmentPage/>}/>
                        <Route path={'human-resources'} element={<HumanResourcesPage/>}/>
                        <Route path={'management-consulting'} element={<ManagementConsultingPage/>}/>
                        <Route path={'training'} element={<TrainingPage/>}/>
                        <Route path={'privacy-policy'} element={<PrivacyPolicyPage/>}/>
                        <Route path={'terms-of-use'} element={<TermsOfUsePage/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
