import React from 'react';
import Header from './header';
import {Outlet} from 'react-router';
import Footer from './footer';

const SharedLayout = () => {
    return (
        <>
            <Header/>
            <Outlet />
            <Footer />
        </>
    );
};

export default SharedLayout;
