import LearnMoreBlock from '../components/LearnMoreBlock';
import React from 'react';
import shapeYellow from '../assets/shape-yellow.svg';
import shapeRed from '../assets/shape-red.svg';
import shapeGreen from '../assets/shape-green.svg';
import shapePurple from '../assets/shape-purple.svg';

const ServicesPage: React.FC = () => {
    return (
        <div
            className={`max-w-[1280px] px-[40px] max-[650px]:px-[16px] w-[100%] items-center pt-[80px] pb-[80px] mx-auto flex items-start gap-[40px] max-[910px]:flex-col`}>
            <div className="flex flex-col w-[50%] max-[910px]:w-full">
                <div className="relative">
                    <h2 className=" after:content[''] after:absolute after:bottom-[32px] rounded-full after:left-0 after:w-[100px] after:h-[4px] after:bg-[#FFCA1D] font-semibold max-w-[384px] max-[910px]:max-w-[497px] text-[32px] mb-[52px]">
                        Services that will help you take your vision a step further
                    </h2>
                </div>

                <p className="text-[18px] max-w-[360px] max-[910px]:max-w-[497px] text-[#585858]">
                    Navigate the possibilities with our range of services.
                </p>
            </div>
            <div className="grid grid-cols-2 gap-[24px] w-[50%] max-[910px]:w-full max-[560px]:grid-cols-1 max-[560px]:gap-[16px]">
                <LearnMoreBlock
                    image={shapeYellow}
                    link="/services/human-resources"
                    title="Human Resources"
                />
                <LearnMoreBlock
                    image={shapeRed}
                    link="/services/executive-recruitment"
                    title="Executive recruitment"
                />
                <LearnMoreBlock
                    image={shapeGreen}
                    link="/services/management-consulting"
                    title="Management Consulting"
                />
                <LearnMoreBlock
                    image={shapePurple}
                    link="/services/training"
                    title="Training"
                />
            </div>
        </div>
    );
};

export default ServicesPage;
