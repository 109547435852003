import {Link} from 'react-router-dom';

const page = () => {
    return (
        <div>
            <div className="bg-[#4B4AEF] max-w-[1440px] mx-auto">
                <div className="py-[80px] w-full max-w-[1280px] px-[40px] max-[650px]:px-[16px] mx-auto">
                    <h1 className="text-white text-[48px] max-[1000px]:text-[38px] font-semibold">
                        Privacy Policy
                    </h1>
                </div>
            </div>
            <div className="w-full max-w-[1280px] px-[40px] max-[650px]:px-[16px] mx-auto py-[80px]">
                <div className="flex flex-col gap-[40px]">
                    {/*Our site uses cookies.*/}
                    <div className="max-w-[1020px]">
            <span className="text-[32px] font-semibold">
              Our site uses cookies.
            </span>
                        <div className="mt-[32px] flex flex-col gap-[24px] ">
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                By using our site and accepting this policy, you agree to the
                                use of cookies in accordance with the terms of this policy. If
                                you do not agree with the use of these cookies, please disable
                                them according to the instructions in this Cookie
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Communication so that the cookies on this site cannot be placed
                                on the device.
                            </p>
                            <p className=" leading-[150%] text-[18px] text-[#585858]">
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>{" "}
                                uses cookies to provide you with a pleasant browsing experience
                                and to allow you to interact with the elements of the website
                                (for example: sending requests to monitor the performance of the
                                website and improving its quality, etc.).
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Because we give transparency to you, we present some useful
                                information about cookies.
                            </p>
                        </div>
                    </div>
                    {/*What is a cookie?*/}
                    <div className="max-w-[1020px]">
                        <span className="text-[32px] font-semibold">What is a cookie?</span>
                        <div className="mt-[32px] flex flex-col gap-[24px] ">
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                We try to make your experience as simple and useful as possible
                                when interacting with the{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>{" "}
                                website. When you visit{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>
                                , our server sends a cookie to your computer. Cookies are used
                                to store information and sometimes to track information about
                                using{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>
                                . There are cookies that expire when you close your browser,
                                while others are used to remember certain information for your
                                next visit to{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>
                                , and these expire after a while. Cookies can be set by the
                                website you visit (“internal cookies”) or can be set by other
                                websites that display information on the page you visit
                                (“external cookies”).
                            </p>
                        </div>
                    </div>
                    {/*How do we use cookies?*/}
                    <div className="max-w-[1020px]">
            <span className="text-[32px] font-semibold">
              How do we use cookies?
            </span>
                        <div className="mt-[32px] flex flex-col gap-[24px] ">
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                We use cookies for:
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Remember you visited us before. This means that we can identify
                                the number of unique visitors we receive, allowing us to be sure
                                that we have sufficient resources depending on the number of
                                visits;
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Customise the commercial elements on{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>
                                ;
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Collect anonymous statistics on how{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint.com
                                </Link>{" "}
                                is used (including how long a user stays on the site) and where
                                our visitors come from, in order to improve the site and find
                                out which are the most visited categories;
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                To gather information about the pages of the site you visit but
                                also other information about other websites you visit, in order
                                to be able to place you in a “market segment”. This information
                                is collected only according to the IP used, but also includes
                                information about your county and city, as well as the name of
                                the internet provider.
                            </p>
                        </div>
                    </div>
                    {/*How can I manage cookies?*/}
                    <div className="max-w-[1020px]">
            <span className="text-[32px] font-semibold">
              How can I manage cookies?
            </span>
                        <div className="mt-[32px] flex flex-col gap-[24px] ">
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Most browsers, smartphones and other Internet-connected devices
                                are set to accept cookies.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                You have different options for managing cookies. You can change
                                your browser settings to prevent cookies from being accepted, or
                                depending on the browser you use, you can receive an alert when
                                a website tries to place a cookie on your browser. In most
                                browsers you can accept cookies from the original website (first
                                party cookies), being able to refuse cookies belonging to
                                another website (third party cookies).
                            </p>
                            <p className=" leading-[150%] text-[18px] text-[#585858]">
                                You can delete cookies stored in your browser using a browser
                                function. This does not mean that your browser will no longer
                                collect cookies in the future, but it does give you the freedom
                                to delete cookies after you have been online. Deleting cookies
                                from one device does not automatically delete them from the
                                other one you use. You must delete all information from all
                                browsers and all used devices independently.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Please note that if you disable cookies, you will restrict
                                certain features of the site and will not submit a request for
                                an offer.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Do cookies contain personal information?
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Cookies do not contain confidential information, such as
                                address, telephone number or bank card details.
                            </p>
                        </div>
                    </div>
                    {/*General terms and conditions regarding the processing
of personal data*/}
                    <div className="max-w-[1020px]">
            <span className="text-[32px] font-semibold">
              General terms and conditions regarding the processing of personal
              data
            </span>
                        <div className="mt-[32px] flex flex-col gap-[24px] ">
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint
                                </Link>{" "}
                                processes the personal data of the Users, the processing being
                                carried out in compliance with the legislation in force, the
                                confidentiality of the personal data and the right to privacy
                                are guaranteed.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Processing of personal data for the purpose of concluding and
                                executing the Contract. The personal data provided by the User,
                                will be processed for the purpose of the execution of this
                                Contract, respectively for the provision of the Services,
                                invoicing and collecting the value of the Services, customer
                                relations, verification and recovery of debts, any other
                                processing necessary for the good performance of the contractual
                                relationship.
                            </p>
                            <p className=" leading-[150%] text-[18px] text-[#585858]">
                                The personal data provided by the User will be disclosed to
                                legal entities, authorities and authorized public institutions,
                                at their request, in accordance with the legal provisions.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Personal data processing for promotional purposes (marketing).
                                The personal data provided, as well as other information
                                provided by the Beneficiary, through this contract (such as:
                                e-mail address, fax number, mobile number, fixed telephone
                                number) may be processed by{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint
                                </Link>{" "}
                                in compliance with the rights of the Users, in particular the
                                right to information and opposition, for the following purposes:
                            </p>
                            <nav className="pl-[20px]">
                                <ul className=" flex flex-col list-disc gap-[16px]">
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        Remarketing and tracking users
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        Making commercial communications
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        {" "}
                                        Communication regarding the status of the services provided
                                        at the client’s request or when it is considered a necessity
                                        (e.g. in case of a technical or unauthorized problem) for
                                        Workpoint products and services.
                                    </li>
                                </ul>
                            </nav>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                Processing of personal data with general identification
                                function. The personal data with identification function are
                                necessary for the correct identification of the client /
                                authorized person in the processing systems of the{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint
                                </Link>
                                , for carrying out the billing operations, respectively those
                                that are necessary or in connection with the execution of the
                                Contract.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                User’s rights regarding the processing of the personal data
                                provided.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                User’s rights regarding the processing of the personal data
                                provided.
                            </p>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                The user has the following rights regarding the personal data
                                provided:
                            </p>
                            <nav className="pl-[20px]">
                                <ul className=" flex flex-col list-disc gap-[16px]">
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to information and access;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to intervene on the data;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to rectification;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to delete data;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to restrict processing;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to data portability;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right of opposition and the individual automated
                                        decision-making process, including the creation of profiles;
                                    </li>
                                    <li className="leading-[150%] text-[18px] text-[#585858]">
                                        the right to file a complaint with the supervisory authority
                                        ANSPDCP – National Supervisory Authority for Personal Data
                                        Processing
                                    </li>
                                </ul>
                            </nav>
                            <p className="leading-[150%] text-[18px] text-[#585858]">
                                In order to exercise these rights, the User will submit to{" "}
                                <Link to="/" className="text-[#00173A] font-semibold">
                                    Workpoint
                                </Link>{" "}
                                a written request, dated and signed, in the attention of the
                                person responsible for the protection of personal data, at the
                                email address{" "}
                                <span className="text-[#00173A] font-semibold">
                  office@Workpoint.com
                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default page;
